'use client'
import React, { useState } from 'react'
import { ImageGenerateItem } from './imageGenerateItem'
import { Button, Modal } from 'flowbite-react'
import { useRouter } from 'next/navigation'
import Image from 'next/image'
import { Properties } from '@/utils/properties'
import Link from 'next/link'
import ImageWithLoader from '../others/ImageWithLoader'

export default function MainPageImageGenerator() {
    const [openModal, setOpenModal] = useState(false)
    const [modalSrc, setModalSrc] = useState<{ src: string }>({ src: '' })

    const handleImageClick = (src: string) => {
        setModalSrc({
            src: src,
        })
        setOpenModal(true)
    }

    return (
        <>
            <ImageGenerateItem selectedImages={[]} handlePromptImageClick={handleImageClick} />
            <Modal dismissible show={openModal} onClose={() => setOpenModal(false)} className='z-[999]'>
                <Modal.Header className="py-2 bg-gray-300">
                    <h5 className="m-0">Generated Image</h5>
                </Modal.Header>
                <Modal.Body className='flex flex-col justify-center bg-gray-200 py-2 px-2'>

                    <ImageWithLoader
                        spinnerSize='xl'
                        src={modalSrc.src}
                        alt='Generated Image'
                        width={400} height={0}
                        className='bg-white bg-opacity-30 rounded-lg bg-blend-screen self-center'
                    />
                    <span className="text-xs text-gray-500 -mb-1 self-end mt-1">
                        *Final Image will be professionally edited
                    </span>
                </Modal.Body>

                <Modal.Footer className='bg-gray-300 flex justify-end py-2 px-1 sm:px-2 pt-3'>
                    <Button color={'dark'} as={Link} href={Properties.routes.PRODUCT_LIST + '?image=' + encodeURIComponent(JSON.stringify(modalSrc))}>
                        Use to customize
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
